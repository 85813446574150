import { SpecialKey } from '@darkforest_eth/constants';

// modal shortcuts
export const MODAL_BACK_SHORTCUT = 't';
export const CLOSE_MODAL = 't';
export const TOGGLE_SETTINGS_PANE = 'h';
export const TOGGLE_HELP_PANE = 'j';
export const TOGGLE_PLUGINS_PANE = 'k';
export const TOGGLE_YOUR_ARTIFACTS_PANE = 'l';
export const TOGGLE_YOUR_PLANETS_DEX_PANE = ';';
export const TOGGLE_TRANSACTIONS_PANE = "'";

// planet context pane shortcuts
export const TOGGLE_PLANET_ARTIFACTS_PANE = 's';
export const TOGGLE_HAT_PANE = 'x';
export const TOGGLE_ABANDON = 'r';
export const INVADE = 'y';
export const MINE_ARTIFACT = 'f';
export const TOGGLE_BROADCAST_PANE = 'z';
export const TOGGLE_UPGRADES_PANE = 'a';
export const TOGGLE_SEND = 'q';
export const TOGGLE_PLANET_INFO_PANE = 'c';
export const EXIT_PANE = SpecialKey.Escape;

// global shortcuts
export const TOGGLE_DIAGNOSTICS_PANE = 'i';
export const TOGGLE_EXPLORE = SpecialKey.Space;
export const TOGGLE_TARGETTING = '`';
